/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountV1Dto,
  ActivityLogV1Dto,
  ActivityLogsPageDto,
  ApplicationQuickstartPipelineV1Dto,
  ApplicationV1Dto,
  ApplicationsPageDto,
  ComputePlanV1Dto,
  DatabaseBackupPageV1Dto,
  DatabasePageV1Dto,
  DatabaseTypeV1Dto,
  DatabaseV1Dto,
  EnvVarSpecV1Dto,
  GithubRepositoryTemplateV1Dto,
  HarborEventV1Dto,
  HarborProjectV1Dto,
  InfraBrokerV1Dto,
  PipelineV1Dto,
  ProblemDetailDto,
  ProblemDetailV1Dto,
  RegionV1Dto,
  RepositoryV1Dto,
  ResourceStateV1Dto,
  RevisionV1Dto,
  SchemaV1Dto,
  SecretFilePreviewSpecV1Dto,
  SecretGroupV1Dto,
  SecretsStorageV1Dto,
  ServicePipelineV1Dto,
  ServicePipelinesPageDto,
  ServiceQuickstartPipelineV1Dto,
  ServiceTagV1Dto,
  ServiceV1Dto,
  TopicV1Dto,
} from '../models/index';
import {
    AccountV1DtoFromJSON,
    AccountV1DtoToJSON,
    ActivityLogV1DtoFromJSON,
    ActivityLogV1DtoToJSON,
    ActivityLogsPageDtoFromJSON,
    ActivityLogsPageDtoToJSON,
    ApplicationQuickstartPipelineV1DtoFromJSON,
    ApplicationQuickstartPipelineV1DtoToJSON,
    ApplicationV1DtoFromJSON,
    ApplicationV1DtoToJSON,
    ApplicationsPageDtoFromJSON,
    ApplicationsPageDtoToJSON,
    ComputePlanV1DtoFromJSON,
    ComputePlanV1DtoToJSON,
    DatabaseBackupPageV1DtoFromJSON,
    DatabaseBackupPageV1DtoToJSON,
    DatabasePageV1DtoFromJSON,
    DatabasePageV1DtoToJSON,
    DatabaseTypeV1DtoFromJSON,
    DatabaseTypeV1DtoToJSON,
    DatabaseV1DtoFromJSON,
    DatabaseV1DtoToJSON,
    EnvVarSpecV1DtoFromJSON,
    EnvVarSpecV1DtoToJSON,
    GithubRepositoryTemplateV1DtoFromJSON,
    GithubRepositoryTemplateV1DtoToJSON,
    HarborEventV1DtoFromJSON,
    HarborEventV1DtoToJSON,
    HarborProjectV1DtoFromJSON,
    HarborProjectV1DtoToJSON,
    InfraBrokerV1DtoFromJSON,
    InfraBrokerV1DtoToJSON,
    PipelineV1DtoFromJSON,
    PipelineV1DtoToJSON,
    ProblemDetailDtoFromJSON,
    ProblemDetailDtoToJSON,
    ProblemDetailV1DtoFromJSON,
    ProblemDetailV1DtoToJSON,
    RegionV1DtoFromJSON,
    RegionV1DtoToJSON,
    RepositoryV1DtoFromJSON,
    RepositoryV1DtoToJSON,
    ResourceStateV1DtoFromJSON,
    ResourceStateV1DtoToJSON,
    RevisionV1DtoFromJSON,
    RevisionV1DtoToJSON,
    SchemaV1DtoFromJSON,
    SchemaV1DtoToJSON,
    SecretFilePreviewSpecV1DtoFromJSON,
    SecretFilePreviewSpecV1DtoToJSON,
    SecretGroupV1DtoFromJSON,
    SecretGroupV1DtoToJSON,
    SecretsStorageV1DtoFromJSON,
    SecretsStorageV1DtoToJSON,
    ServicePipelineV1DtoFromJSON,
    ServicePipelineV1DtoToJSON,
    ServicePipelinesPageDtoFromJSON,
    ServicePipelinesPageDtoToJSON,
    ServiceQuickstartPipelineV1DtoFromJSON,
    ServiceQuickstartPipelineV1DtoToJSON,
    ServiceTagV1DtoFromJSON,
    ServiceTagV1DtoToJSON,
    ServiceV1DtoFromJSON,
    ServiceV1DtoToJSON,
    TopicV1DtoFromJSON,
    TopicV1DtoToJSON,
} from '../models/index';

export interface CreateAccountV1Request {
    accountV1Dto: AccountV1Dto;
    application: string;
    environment: string;
}

export interface CreateActivityLogRequest {
    activityLogV1Dto: ActivityLogV1Dto;
}

export interface CreateApplicationRequest {
    applicationV1Dto: ApplicationV1Dto;
    macAppId?: string;
    macPlatformId?: string;
}

export interface CreateApplicationQuickstartPipelineRequest {
    applicationQuickstartPipelineV1Dto: ApplicationQuickstartPipelineV1Dto;
}

export interface CreateApplicationV1Request {
    applicationV1Dto: ApplicationV1Dto;
}

export interface CreateApplicationWithTeamRequest {
    applicationV1Dto: ApplicationV1Dto;
    createTeam: boolean;
    macAppId?: string;
    macPlatformId?: string;
}

export interface CreateDatabaseV1Request {
    databaseV1Dto: DatabaseV1Dto;
    application: string;
    environment: string;
}

export interface CreateEnvVarsPreviewRequest {
    requestBody: { [key: string]: EnvVarSpecV1Dto | undefined; };
    application: string;
    environment: string;
    service: string;
}

export interface CreateHarborEventRequest {
    harborEventV1Dto: HarborEventV1Dto;
}

export interface CreateHarborProjectRequest {
    application: string;
    harborProjectV1Dto?: HarborProjectV1Dto;
}

export interface CreateInfraBrokerRequest {
    infraBrokerV1Dto: InfraBrokerV1Dto;
}

export interface CreateRevisionRequest {
    revisionV1Dto: RevisionV1Dto;
    application: string;
    environment: string;
}

export interface CreateSchemaV1Request {
    schemaV1Dto: SchemaV1Dto;
    application: string;
    environment: string;
}

export interface CreateSecretFilePreviewRequest {
    secretFilePreviewSpecV1Dto: SecretFilePreviewSpecV1Dto;
    application: string;
    environment: string;
    service: string;
}

export interface CreateSecretGroupRequest {
    secretGroupV1Dto: SecretGroupV1Dto;
    application: string;
    environment: string;
}

export interface CreateServiceRequest {
    serviceV1Dto: ServiceV1Dto;
    application: string;
    environment: string;
    xServiceCopy?: boolean;
}

export interface CreateServicePipelineRequest {
    servicePipelineV1Dto: ServicePipelineV1Dto;
    application: string;
    environment: string;
    service: string;
}

export interface CreateServiceQuickstartPipelineRequest {
    serviceQuickstartPipelineV1Dto: ServiceQuickstartPipelineV1Dto;
}

export interface CreateTopicV1Request {
    topicV1Dto: TopicV1Dto;
    application: string;
    environment: string;
}

export interface DeleteAccountV1Request {
    application: string;
    environment: string;
    accountId: string;
}

export interface DeleteApplicationRequest {
    application: string;
    forceDelete?: boolean;
}

export interface DeleteDatabaseV1Request {
    application: string;
    environment: string;
    databaseId: string;
    databaseType: DatabaseTypeV1Dto;
}

export interface DeleteHarborProjectRequest {
    application: string;
}

export interface DeleteInfraBrokerRequest {
    infrabroker: string;
}

export interface DeleteRevisionRequest {
    application: string;
    environment: string;
    revision: string;
}

export interface DeleteRevisionInstanceRequest {
    application: string;
    environment: string;
    revision: string;
    instance: string;
}

export interface DeleteSchemaV1Request {
    application: string;
    environment: string;
    schemaId: string;
}

export interface DeleteSecretGroupRequest {
    application: string;
    environment: string;
    secretGroup: string;
    version?: number;
}

export interface DeleteServiceRequest {
    application: string;
    environment: string;
    service: string;
}

export interface DeleteTopicV1Request {
    application: string;
    environment: string;
    topicId: string;
}

export interface GetAccountV1Request {
    application: string;
    environment: string;
    accountId: string;
}

export interface GetAccountsV1Request {
    application: string;
    environment: string;
    onlyReadyToUse?: boolean;
}

export interface GetActivityLogRequest {
    activitylog: string;
}

export interface GetActivityLogsRequest {
    applicationName: string;
    page?: number;
    size?: number;
    environmentName?: string;
    serviceName?: string;
    revisionName?: string;
    instanceName?: string;
}

export interface GetApplicationRequest {
    application: string;
}

export interface GetApplicationQuickstartPipelineRequest {
    pipelineName: string;
}

export interface GetApplicationsRequest {
    teamRefs?: Array<string>;
}

export interface GetComputePlansV1Request {
    application: string;
    environment: string;
    databaseType: DatabaseTypeV1Dto;
    region?: string;
}

export interface GetDatabaseBackupsV1Request {
    application: string;
    environment: string;
    databaseId: string;
    databaseType: DatabaseTypeV1Dto;
    status?: ResourceStateV1Dto;
    page?: number;
    size?: number;
}

export interface GetDatabaseV1Request {
    application: string;
    environment: string;
    databaseId: string;
    databaseType: DatabaseTypeV1Dto;
}

export interface GetDatabasesV1Request {
    application: string;
    environment: string;
    databaseType: DatabaseTypeV1Dto;
    status?: ResourceStateV1Dto;
    page?: number;
    size?: number;
}

export interface GetHarborProjectForApplicationRequest {
    application: string;
}

export interface GetPagedApplicationsRequest {
    page?: number;
    size?: number;
    teamRefs?: Array<string>;
    applicationNameSearchKey?: string;
}

export interface GetPipelineRequest {
    pipeline: string;
}

export interface GetRegionsV1Request {
    application: string;
    environment: string;
    databaseType: DatabaseTypeV1Dto;
}

export interface GetRepositoriesRequest {
    query: string;
}

export interface GetRevisionRequest {
    application: string;
    environment: string;
    revision: string;
}

export interface GetRevisionsRequest {
    application: string;
    environment: string;
    service: string;
    onlyActive?: boolean;
}

export interface GetSchemaV1Request {
    application: string;
    environment: string;
    schemaId: string;
}

export interface GetSchemasV1Request {
    application: string;
    environment: string;
    onlyReadyToUse?: boolean;
}

export interface GetSecretGroupRequest {
    application: string;
    environment: string;
    secretGroup: string;
    version?: number;
}

export interface GetSecretGroupsRequest {
    application: string;
    environment: string;
}

export interface GetSecretsStorageV1Request {
    application: string;
}

export interface GetServiceRequest {
    application: string;
    environment: string;
    service: string;
}

export interface GetServicePipelineRequest {
    application: string;
    environment: string;
    service: string;
    pipelineName: string;
}

export interface GetServicePipelinesRequest {
    application: string;
    environment: string;
    service: string;
    page?: number;
    size?: number;
}

export interface GetServiceQuickstartPipelineRequest {
    pipelineName: string;
}

export interface GetServiceTagsRequest {
    application: string;
    environment: string;
    service: string;
}

export interface GetServicesRequest {
    application: string;
    environment: string;
}

export interface GetTopicV1Request {
    application: string;
    environment: string;
    topicId: string;
    accountsApplicationCode?: string;
}

export interface GetTopicsV1Request {
    application: string;
    environment: string;
    owned: boolean;
    accountsApplicationCode?: string;
    onlyReadyToUse?: boolean;
}

export interface PatchServiceRequest {
    requestBody: { [key: string]: object | undefined; };
    application: string;
    environment: string;
    service: string;
}

export interface UpdateAccountV1Request {
    accountV1Dto: AccountV1Dto;
    application: string;
    environment: string;
    accountId: string;
}

export interface UpdateDatabaseV1Request {
    databaseV1Dto: DatabaseV1Dto;
    application: string;
    environment: string;
    databaseId: string;
}

export interface UpdateHarborProjectRequest {
    harborProjectV1Dto: HarborProjectV1Dto;
    application: string;
}

export interface UpdateInfraBrokerRequest {
    infraBrokerV1Dto: InfraBrokerV1Dto;
    infrabroker: string;
}

export interface UpdateRevisionRequest {
    requestBody: { [key: string]: object | undefined; };
    application: string;
    environment: string;
    revision: string;
}

export interface UpdateRevisionEnabledRequest {
    application: string;
    environment: string;
    revision: string;
    enabled: boolean;
}

export interface UpdateSchemaV1Request {
    schemaV1Dto: SchemaV1Dto;
    application: string;
    environment: string;
    schemaId: string;
}

export interface UpdateSecretGroupRequest {
    secretGroupV1Dto: SecretGroupV1Dto;
    application: string;
    environment: string;
    secretGroup: string;
}

export interface UpdateServiceRequest {
    serviceV1Dto: ServiceV1Dto;
    application: string;
    environment: string;
    service: string;
}

export interface UpdateTopicV1Request {
    topicV1Dto: TopicV1Dto;
    application: string;
    environment: string;
    topicId: string;
}

export interface UpsertSecretsStorageV1Request {
    secretsStorageV1Dto: SecretsStorageV1Dto;
    application: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create a retina account.
     */
    async createAccountV1Raw(requestParameters: CreateAccountV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineV1Dto>> {
        if (requestParameters.accountV1Dto === null || requestParameters.accountV1Dto === undefined) {
            throw new runtime.RequiredError('accountV1Dto','Required parameter requestParameters.accountV1Dto was null or undefined when calling createAccountV1.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createAccountV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createAccountV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/accounts/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountV1DtoToJSON(requestParameters.accountV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Create a retina account.
     */
    async createAccountV1(accountV1Dto: AccountV1Dto, application: string, environment: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineV1Dto> {
        const response = await this.createAccountV1Raw({ accountV1Dto: accountV1Dto, application: application, environment: environment }, initOverrides);
        return await response.value();
    }

    /**
     * Create activity log
     */
    async createActivityLogRaw(requestParameters: CreateActivityLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActivityLogV1Dto>> {
        if (requestParameters.activityLogV1Dto === null || requestParameters.activityLogV1Dto === undefined) {
            throw new runtime.RequiredError('activityLogV1Dto','Required parameter requestParameters.activityLogV1Dto was null or undefined when calling createActivityLog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activitylogs/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivityLogV1DtoToJSON(requestParameters.activityLogV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityLogV1DtoFromJSON(jsonValue));
    }

    /**
     * Create activity log
     */
    async createActivityLog(activityLogV1Dto: ActivityLogV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActivityLogV1Dto> {
        const response = await this.createActivityLogRaw({ activityLogV1Dto: activityLogV1Dto }, initOverrides);
        return await response.value();
    }

    /**
     * Create application
     */
    async createApplicationRaw(requestParameters: CreateApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineV1Dto>> {
        if (requestParameters.applicationV1Dto === null || requestParameters.applicationV1Dto === undefined) {
            throw new runtime.RequiredError('applicationV1Dto','Required parameter requestParameters.applicationV1Dto was null or undefined when calling createApplication.');
        }

        const queryParameters: any = {};

        if (requestParameters.macAppId !== undefined) {
            queryParameters['macAppId'] = requestParameters.macAppId;
        }

        if (requestParameters.macPlatformId !== undefined) {
            queryParameters['macPlatformId'] = requestParameters.macPlatformId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationV1DtoToJSON(requestParameters.applicationV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Create application
     */
    async createApplication(applicationV1Dto: ApplicationV1Dto, macAppId?: string, macPlatformId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineV1Dto> {
        const response = await this.createApplicationRaw({ applicationV1Dto: applicationV1Dto, macAppId: macAppId, macPlatformId: macPlatformId }, initOverrides);
        return await response.value();
    }

    /**
     * Create application quickstart pipeline
     */
    async createApplicationQuickstartPipelineRaw(requestParameters: CreateApplicationQuickstartPipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationQuickstartPipelineV1Dto>> {
        if (requestParameters.applicationQuickstartPipelineV1Dto === null || requestParameters.applicationQuickstartPipelineV1Dto === undefined) {
            throw new runtime.RequiredError('applicationQuickstartPipelineV1Dto','Required parameter requestParameters.applicationQuickstartPipelineV1Dto was null or undefined when calling createApplicationQuickstartPipeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationquickstartpipelines/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationQuickstartPipelineV1DtoToJSON(requestParameters.applicationQuickstartPipelineV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationQuickstartPipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Create application quickstart pipeline
     */
    async createApplicationQuickstartPipeline(applicationQuickstartPipelineV1Dto: ApplicationQuickstartPipelineV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationQuickstartPipelineV1Dto> {
        const response = await this.createApplicationQuickstartPipelineRaw({ applicationQuickstartPipelineV1Dto: applicationQuickstartPipelineV1Dto }, initOverrides);
        return await response.value();
    }

    /**
     * Create application
     * @deprecated
     */
    async createApplicationV1Raw(requestParameters: CreateApplicationV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.applicationV1Dto === null || requestParameters.applicationV1Dto === undefined) {
            throw new runtime.RequiredError('applicationV1Dto','Required parameter requestParameters.applicationV1Dto was null or undefined when calling createApplicationV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationV1DtoToJSON(requestParameters.applicationV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create application
     * @deprecated
     */
    async createApplicationV1(applicationV1Dto: ApplicationV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createApplicationV1Raw({ applicationV1Dto: applicationV1Dto }, initOverrides);
    }

    /**
     * Create application (with a create team flag). Endpoint dedicated for MDP front-end
     */
    async createApplicationWithTeamRaw(requestParameters: CreateApplicationWithTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineV1Dto>> {
        if (requestParameters.applicationV1Dto === null || requestParameters.applicationV1Dto === undefined) {
            throw new runtime.RequiredError('applicationV1Dto','Required parameter requestParameters.applicationV1Dto was null or undefined when calling createApplicationWithTeam.');
        }

        if (requestParameters.createTeam === null || requestParameters.createTeam === undefined) {
            throw new runtime.RequiredError('createTeam','Required parameter requestParameters.createTeam was null or undefined when calling createApplicationWithTeam.');
        }

        const queryParameters: any = {};

        if (requestParameters.createTeam !== undefined) {
            queryParameters['createTeam'] = requestParameters.createTeam;
        }

        if (requestParameters.macAppId !== undefined) {
            queryParameters['macAppId'] = requestParameters.macAppId;
        }

        if (requestParameters.macPlatformId !== undefined) {
            queryParameters['macPlatformId'] = requestParameters.macPlatformId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationV1DtoToJSON(requestParameters.applicationV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Create application (with a create team flag). Endpoint dedicated for MDP front-end
     */
    async createApplicationWithTeam(applicationV1Dto: ApplicationV1Dto, createTeam: boolean, macAppId?: string, macPlatformId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineV1Dto> {
        const response = await this.createApplicationWithTeamRaw({ applicationV1Dto: applicationV1Dto, createTeam: createTeam, macAppId: macAppId, macPlatformId: macPlatformId }, initOverrides);
        return await response.value();
    }

    /**
     * Create a resource for application. This operation is asynchronous. Returned object contains the \'status\' field that can be used to track the operation progress via polling the corresponding \'GET\' endpoint. 
     */
    async createDatabaseV1Raw(requestParameters: CreateDatabaseV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatabaseV1Dto>> {
        if (requestParameters.databaseV1Dto === null || requestParameters.databaseV1Dto === undefined) {
            throw new runtime.RequiredError('databaseV1Dto','Required parameter requestParameters.databaseV1Dto was null or undefined when calling createDatabaseV1.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createDatabaseV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createDatabaseV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/databases/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DatabaseV1DtoToJSON(requestParameters.databaseV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabaseV1DtoFromJSON(jsonValue));
    }

    /**
     * Create a resource for application. This operation is asynchronous. Returned object contains the \'status\' field that can be used to track the operation progress via polling the corresponding \'GET\' endpoint. 
     */
    async createDatabaseV1(databaseV1Dto: DatabaseV1Dto, application: string, environment: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatabaseV1Dto> {
        const response = await this.createDatabaseV1Raw({ databaseV1Dto: databaseV1Dto, application: application, environment: environment }, initOverrides);
        return await response.value();
    }

    /**
     * Returns environment preview based on service data.
     */
    async createEnvVarsPreviewRaw(requestParameters: CreateEnvVarsPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: string | undefined; }>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling createEnvVarsPreview.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createEnvVarsPreview.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createEnvVarsPreview.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling createEnvVarsPreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/envvarspreviews/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns environment preview based on service data.
     */
    async createEnvVarsPreview(requestBody: { [key: string]: EnvVarSpecV1Dto | undefined; }, application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: string | undefined; }> {
        const response = await this.createEnvVarsPreviewRaw({ requestBody: requestBody, application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

    /**
     * Create harbor event
     */
    async createHarborEventRaw(requestParameters: CreateHarborEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.harborEventV1Dto === null || requestParameters.harborEventV1Dto === undefined) {
            throw new runtime.RequiredError('harborEventV1Dto','Required parameter requestParameters.harborEventV1Dto was null or undefined when calling createHarborEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/harborevent/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HarborEventV1DtoToJSON(requestParameters.harborEventV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create harbor event
     */
    async createHarborEvent(harborEventV1Dto: HarborEventV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createHarborEventRaw({ harborEventV1Dto: harborEventV1Dto }, initOverrides);
    }

    /**
     * Create harbor project
     */
    async createHarborProjectRaw(requestParameters: CreateHarborProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createHarborProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/harborprojects/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HarborProjectV1DtoToJSON(requestParameters.harborProjectV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create harbor project
     */
    async createHarborProject(application: string, harborProjectV1Dto?: HarborProjectV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createHarborProjectRaw({ application: application, harborProjectV1Dto: harborProjectV1Dto }, initOverrides);
    }

    /**
     * Create infra broker registration
     */
    async createInfraBrokerRaw(requestParameters: CreateInfraBrokerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfraBrokerV1Dto>> {
        if (requestParameters.infraBrokerV1Dto === null || requestParameters.infraBrokerV1Dto === undefined) {
            throw new runtime.RequiredError('infraBrokerV1Dto','Required parameter requestParameters.infraBrokerV1Dto was null or undefined when calling createInfraBroker.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/infrabrokers/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfraBrokerV1DtoToJSON(requestParameters.infraBrokerV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfraBrokerV1DtoFromJSON(jsonValue));
    }

    /**
     * Create infra broker registration
     */
    async createInfraBroker(infraBrokerV1Dto: InfraBrokerV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfraBrokerV1Dto> {
        const response = await this.createInfraBrokerRaw({ infraBrokerV1Dto: infraBrokerV1Dto }, initOverrides);
        return await response.value();
    }

    /**
     * Create a new server-group within an application environment.
     */
    async createRevisionRaw(requestParameters: CreateRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.revisionV1Dto === null || requestParameters.revisionV1Dto === undefined) {
            throw new runtime.RequiredError('revisionV1Dto','Required parameter requestParameters.revisionV1Dto was null or undefined when calling createRevision.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createRevision.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createRevision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/revisions/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RevisionV1DtoToJSON(requestParameters.revisionV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new server-group within an application environment.
     */
    async createRevision(revisionV1Dto: RevisionV1Dto, application: string, environment: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createRevisionRaw({ revisionV1Dto: revisionV1Dto, application: application, environment: environment }, initOverrides);
    }

    /**
     * Create a retina schema.
     */
    async createSchemaV1Raw(requestParameters: CreateSchemaV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineV1Dto>> {
        if (requestParameters.schemaV1Dto === null || requestParameters.schemaV1Dto === undefined) {
            throw new runtime.RequiredError('schemaV1Dto','Required parameter requestParameters.schemaV1Dto was null or undefined when calling createSchemaV1.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createSchemaV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createSchemaV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/schemas/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SchemaV1DtoToJSON(requestParameters.schemaV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Create a retina schema.
     */
    async createSchemaV1(schemaV1Dto: SchemaV1Dto, application: string, environment: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineV1Dto> {
        const response = await this.createSchemaV1Raw({ schemaV1Dto: schemaV1Dto, application: application, environment: environment }, initOverrides);
        return await response.value();
    }

    /**
     * Returns secret file rendered contents as it will be provided to workload.
     */
    async createSecretFilePreviewRaw(requestParameters: CreateSecretFilePreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.secretFilePreviewSpecV1Dto === null || requestParameters.secretFilePreviewSpecV1Dto === undefined) {
            throw new runtime.RequiredError('secretFilePreviewSpecV1Dto','Required parameter requestParameters.secretFilePreviewSpecV1Dto was null or undefined when calling createSecretFilePreview.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createSecretFilePreview.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createSecretFilePreview.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling createSecretFilePreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/secretfilepreview`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SecretFilePreviewSpecV1DtoToJSON(requestParameters.secretFilePreviewSpecV1Dto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns secret file rendered contents as it will be provided to workload.
     */
    async createSecretFilePreview(secretFilePreviewSpecV1Dto: SecretFilePreviewSpecV1Dto, application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createSecretFilePreviewRaw({ secretFilePreviewSpecV1Dto: secretFilePreviewSpecV1Dto, application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

    /**
     * Create secret group
     */
    async createSecretGroupRaw(requestParameters: CreateSecretGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecretGroupV1Dto>> {
        if (requestParameters.secretGroupV1Dto === null || requestParameters.secretGroupV1Dto === undefined) {
            throw new runtime.RequiredError('secretGroupV1Dto','Required parameter requestParameters.secretGroupV1Dto was null or undefined when calling createSecretGroup.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createSecretGroup.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createSecretGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/secretgroups/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SecretGroupV1DtoToJSON(requestParameters.secretGroupV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecretGroupV1DtoFromJSON(jsonValue));
    }

    /**
     * Create secret group
     */
    async createSecretGroup(secretGroupV1Dto: SecretGroupV1Dto, application: string, environment: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecretGroupV1Dto> {
        const response = await this.createSecretGroupRaw({ secretGroupV1Dto: secretGroupV1Dto, application: application, environment: environment }, initOverrides);
        return await response.value();
    }

    /**
     * Create service
     */
    async createServiceRaw(requestParameters: CreateServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceV1Dto>> {
        if (requestParameters.serviceV1Dto === null || requestParameters.serviceV1Dto === undefined) {
            throw new runtime.RequiredError('serviceV1Dto','Required parameter requestParameters.serviceV1Dto was null or undefined when calling createService.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createService.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xServiceCopy !== undefined && requestParameters.xServiceCopy !== null) {
            headerParameters['X-Service-Copy'] = String(requestParameters.xServiceCopy);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceV1DtoToJSON(requestParameters.serviceV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceV1DtoFromJSON(jsonValue));
    }

    /**
     * Create service
     */
    async createService(serviceV1Dto: ServiceV1Dto, application: string, environment: string, xServiceCopy?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceV1Dto> {
        const response = await this.createServiceRaw({ serviceV1Dto: serviceV1Dto, application: application, environment: environment, xServiceCopy: xServiceCopy }, initOverrides);
        return await response.value();
    }

    /**
     * Create service pipeline
     */
    async createServicePipelineRaw(requestParameters: CreateServicePipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServicePipelineV1Dto>> {
        if (requestParameters.servicePipelineV1Dto === null || requestParameters.servicePipelineV1Dto === undefined) {
            throw new runtime.RequiredError('servicePipelineV1Dto','Required parameter requestParameters.servicePipelineV1Dto was null or undefined when calling createServicePipeline.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createServicePipeline.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createServicePipeline.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling createServicePipeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/pipelines/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServicePipelineV1DtoToJSON(requestParameters.servicePipelineV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServicePipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Create service pipeline
     */
    async createServicePipeline(servicePipelineV1Dto: ServicePipelineV1Dto, application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServicePipelineV1Dto> {
        const response = await this.createServicePipelineRaw({ servicePipelineV1Dto: servicePipelineV1Dto, application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

    /**
     * Create service quickstart pipeline
     */
    async createServiceQuickstartPipelineRaw(requestParameters: CreateServiceQuickstartPipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceQuickstartPipelineV1Dto>> {
        if (requestParameters.serviceQuickstartPipelineV1Dto === null || requestParameters.serviceQuickstartPipelineV1Dto === undefined) {
            throw new runtime.RequiredError('serviceQuickstartPipelineV1Dto','Required parameter requestParameters.serviceQuickstartPipelineV1Dto was null or undefined when calling createServiceQuickstartPipeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/servicequickstartpipelines/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceQuickstartPipelineV1DtoToJSON(requestParameters.serviceQuickstartPipelineV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceQuickstartPipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Create service quickstart pipeline
     */
    async createServiceQuickstartPipeline(serviceQuickstartPipelineV1Dto: ServiceQuickstartPipelineV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceQuickstartPipelineV1Dto> {
        const response = await this.createServiceQuickstartPipelineRaw({ serviceQuickstartPipelineV1Dto: serviceQuickstartPipelineV1Dto }, initOverrides);
        return await response.value();
    }

    /**
     * Create a topic for application.
     */
    async createTopicV1Raw(requestParameters: CreateTopicV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineV1Dto>> {
        if (requestParameters.topicV1Dto === null || requestParameters.topicV1Dto === undefined) {
            throw new runtime.RequiredError('topicV1Dto','Required parameter requestParameters.topicV1Dto was null or undefined when calling createTopicV1.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling createTopicV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling createTopicV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/topics/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TopicV1DtoToJSON(requestParameters.topicV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Create a topic for application.
     */
    async createTopicV1(topicV1Dto: TopicV1Dto, application: string, environment: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineV1Dto> {
        const response = await this.createTopicV1Raw({ topicV1Dto: topicV1Dto, application: application, environment: environment }, initOverrides);
        return await response.value();
    }

    /**
     * Delete application retina account;
     */
    async deleteAccountV1Raw(requestParameters: DeleteAccountV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteAccountV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling deleteAccountV1.');
        }

        if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
            throw new runtime.RequiredError('accountId','Required parameter requestParameters.accountId was null or undefined when calling deleteAccountV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/accounts/v1/{accountId}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"accountId"}}`, encodeURIComponent(String(requestParameters.accountId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Delete application retina account;
     */
    async deleteAccountV1(application: string, environment: string, accountId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineV1Dto> {
        const response = await this.deleteAccountV1Raw({ application: application, environment: environment, accountId: accountId }, initOverrides);
        return await response.value();
    }

    /**
     * Delete application
     */
    async deleteApplicationRaw(requestParameters: DeleteApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteApplication.');
        }

        const queryParameters: any = {};

        if (requestParameters.forceDelete !== undefined) {
            queryParameters['forceDelete'] = requestParameters.forceDelete;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete application
     */
    async deleteApplication(application: string, forceDelete?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteApplicationRaw({ application: application, forceDelete: forceDelete }, initOverrides);
    }

    /**
     * Delete the resource owned by the application. This operation is asynchronous. Resource considered to be deleted  when corresponding \'GET\' endpoint returns 404 status code. 
     */
    async deleteDatabaseV1Raw(requestParameters: DeleteDatabaseV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteDatabaseV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling deleteDatabaseV1.');
        }

        if (requestParameters.databaseId === null || requestParameters.databaseId === undefined) {
            throw new runtime.RequiredError('databaseId','Required parameter requestParameters.databaseId was null or undefined when calling deleteDatabaseV1.');
        }

        if (requestParameters.databaseType === null || requestParameters.databaseType === undefined) {
            throw new runtime.RequiredError('databaseType','Required parameter requestParameters.databaseType was null or undefined when calling deleteDatabaseV1.');
        }

        const queryParameters: any = {};

        if (requestParameters.databaseType !== undefined) {
            queryParameters['databaseType'] = requestParameters.databaseType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/databases/v1/{databaseId}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"databaseId"}}`, encodeURIComponent(String(requestParameters.databaseId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the resource owned by the application. This operation is asynchronous. Resource considered to be deleted  when corresponding \'GET\' endpoint returns 404 status code. 
     */
    async deleteDatabaseV1(application: string, environment: string, databaseId: string, databaseType: DatabaseTypeV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDatabaseV1Raw({ application: application, environment: environment, databaseId: databaseId, databaseType: databaseType }, initOverrides);
    }

    /**
     * Delete the harbor project. This operation is asynchronous. Harbor Project considered to be deleted  when corresponding \'GET\' endpoint returns 404 status code. 
     */
    async deleteHarborProjectRaw(requestParameters: DeleteHarborProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteHarborProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/harborprojects/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the harbor project. This operation is asynchronous. Harbor Project considered to be deleted  when corresponding \'GET\' endpoint returns 404 status code. 
     */
    async deleteHarborProject(application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteHarborProjectRaw({ application: application }, initOverrides);
    }

    /**
     * Delete infra broker
     */
    async deleteInfraBrokerRaw(requestParameters: DeleteInfraBrokerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.infrabroker === null || requestParameters.infrabroker === undefined) {
            throw new runtime.RequiredError('infrabroker','Required parameter requestParameters.infrabroker was null or undefined when calling deleteInfraBroker.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/infrabrokers/v1/{infrabroker}`.replace(`{${"infrabroker"}}`, encodeURIComponent(String(requestParameters.infrabroker))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete infra broker
     */
    async deleteInfraBroker(infrabroker: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteInfraBrokerRaw({ infrabroker: infrabroker }, initOverrides);
    }

    /**
     * Delete revision
     */
    async deleteRevisionRaw(requestParameters: DeleteRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteRevision.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling deleteRevision.');
        }

        if (requestParameters.revision === null || requestParameters.revision === undefined) {
            throw new runtime.RequiredError('revision','Required parameter requestParameters.revision was null or undefined when calling deleteRevision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/revisions/v1/{revision}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"revision"}}`, encodeURIComponent(String(requestParameters.revision))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete revision
     */
    async deleteRevision(application: string, environment: string, revision: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRevisionRaw({ application: application, environment: environment, revision: revision }, initOverrides);
    }

    /**
     * Delete an instance
     */
    async deleteRevisionInstanceRaw(requestParameters: DeleteRevisionInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteRevisionInstance.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling deleteRevisionInstance.');
        }

        if (requestParameters.revision === null || requestParameters.revision === undefined) {
            throw new runtime.RequiredError('revision','Required parameter requestParameters.revision was null or undefined when calling deleteRevisionInstance.');
        }

        if (requestParameters.instance === null || requestParameters.instance === undefined) {
            throw new runtime.RequiredError('instance','Required parameter requestParameters.instance was null or undefined when calling deleteRevisionInstance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/revisions/{revision}/instances/v1/{instance}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"revision"}}`, encodeURIComponent(String(requestParameters.revision))).replace(`{${"instance"}}`, encodeURIComponent(String(requestParameters.instance))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an instance
     */
    async deleteRevisionInstance(application: string, environment: string, revision: string, instance: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRevisionInstanceRaw({ application: application, environment: environment, revision: revision, instance: instance }, initOverrides);
    }

    /**
     * Delete application retina schema;
     */
    async deleteSchemaV1Raw(requestParameters: DeleteSchemaV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteSchemaV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling deleteSchemaV1.');
        }

        if (requestParameters.schemaId === null || requestParameters.schemaId === undefined) {
            throw new runtime.RequiredError('schemaId','Required parameter requestParameters.schemaId was null or undefined when calling deleteSchemaV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/schemas/v1/{schemaId}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"schemaId"}}`, encodeURIComponent(String(requestParameters.schemaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Delete application retina schema;
     */
    async deleteSchemaV1(application: string, environment: string, schemaId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineV1Dto> {
        const response = await this.deleteSchemaV1Raw({ application: application, environment: environment, schemaId: schemaId }, initOverrides);
        return await response.value();
    }

    /**
     * Delete secret group
     */
    async deleteSecretGroupRaw(requestParameters: DeleteSecretGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteSecretGroup.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling deleteSecretGroup.');
        }

        if (requestParameters.secretGroup === null || requestParameters.secretGroup === undefined) {
            throw new runtime.RequiredError('secretGroup','Required parameter requestParameters.secretGroup was null or undefined when calling deleteSecretGroup.');
        }

        const queryParameters: any = {};

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/secretgroups/v1/{secretGroup}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"secretGroup"}}`, encodeURIComponent(String(requestParameters.secretGroup))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete secret group
     */
    async deleteSecretGroup(application: string, environment: string, secretGroup: string, version?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSecretGroupRaw({ application: application, environment: environment, secretGroup: secretGroup, version: version }, initOverrides);
    }

    /**
     * Delete service
     */
    async deleteServiceRaw(requestParameters: DeleteServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteService.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling deleteService.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling deleteService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/v1/{service}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete service
     */
    async deleteService(application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteServiceRaw({ application: application, environment: environment, service: service }, initOverrides);
    }

    /**
     * Delete a topic
     */
    async deleteTopicV1Raw(requestParameters: DeleteTopicV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling deleteTopicV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling deleteTopicV1.');
        }

        if (requestParameters.topicId === null || requestParameters.topicId === undefined) {
            throw new runtime.RequiredError('topicId','Required parameter requestParameters.topicId was null or undefined when calling deleteTopicV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/topics/v1/{topicId}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"topicId"}}`, encodeURIComponent(String(requestParameters.topicId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Delete a topic
     */
    async deleteTopicV1(application: string, environment: string, topicId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineV1Dto> {
        const response = await this.deleteTopicV1Raw({ application: application, environment: environment, topicId: topicId }, initOverrides);
        return await response.value();
    }

    /**
     * Get retina account owned by the application
     */
    async getAccountV1Raw(requestParameters: GetAccountV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getAccountV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getAccountV1.');
        }

        if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
            throw new runtime.RequiredError('accountId','Required parameter requestParameters.accountId was null or undefined when calling getAccountV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/accounts/v1/{accountId}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"accountId"}}`, encodeURIComponent(String(requestParameters.accountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountV1DtoFromJSON(jsonValue));
    }

    /**
     * Get retina account owned by the application
     */
    async getAccountV1(application: string, environment: string, accountId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountV1Dto> {
        const response = await this.getAccountV1Raw({ application: application, environment: environment, accountId: accountId }, initOverrides);
        return await response.value();
    }

    /**
     * Get the list of accounts owned by the application
     */
    async getAccountsV1Raw(requestParameters: GetAccountsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AccountV1Dto>>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getAccountsV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getAccountsV1.');
        }

        const queryParameters: any = {};

        if (requestParameters.onlyReadyToUse !== undefined) {
            queryParameters['onlyReadyToUse'] = requestParameters.onlyReadyToUse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/accounts/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccountV1DtoFromJSON));
    }

    /**
     * Get the list of accounts owned by the application
     */
    async getAccountsV1(application: string, environment: string, onlyReadyToUse?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AccountV1Dto>> {
        const response = await this.getAccountsV1Raw({ application: application, environment: environment, onlyReadyToUse: onlyReadyToUse }, initOverrides);
        return await response.value();
    }

    /**
     * Get single activity log
     */
    async getActivityLogRaw(requestParameters: GetActivityLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActivityLogV1Dto>> {
        if (requestParameters.activitylog === null || requestParameters.activitylog === undefined) {
            throw new runtime.RequiredError('activitylog','Required parameter requestParameters.activitylog was null or undefined when calling getActivityLog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activitylogs/v1/{activitylog}`.replace(`{${"activitylog"}}`, encodeURIComponent(String(requestParameters.activitylog))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityLogV1DtoFromJSON(jsonValue));
    }

    /**
     * Get single activity log
     */
    async getActivityLog(activitylog: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActivityLogV1Dto> {
        const response = await this.getActivityLogRaw({ activitylog: activitylog }, initOverrides);
        return await response.value();
    }

    /**
     * Get activity log s related to particular environment
     */
    async getActivityLogsRaw(requestParameters: GetActivityLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActivityLogsPageDto>> {
        if (requestParameters.applicationName === null || requestParameters.applicationName === undefined) {
            throw new runtime.RequiredError('applicationName','Required parameter requestParameters.applicationName was null or undefined when calling getActivityLogs.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.applicationName !== undefined) {
            queryParameters['applicationName'] = requestParameters.applicationName;
        }

        if (requestParameters.environmentName !== undefined) {
            queryParameters['environmentName'] = requestParameters.environmentName;
        }

        if (requestParameters.serviceName !== undefined) {
            queryParameters['serviceName'] = requestParameters.serviceName;
        }

        if (requestParameters.revisionName !== undefined) {
            queryParameters['revisionName'] = requestParameters.revisionName;
        }

        if (requestParameters.instanceName !== undefined) {
            queryParameters['instanceName'] = requestParameters.instanceName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activitylogs/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityLogsPageDtoFromJSON(jsonValue));
    }

    /**
     * Get activity log s related to particular environment
     */
    async getActivityLogs(applicationName: string, page?: number, size?: number, environmentName?: string, serviceName?: string, revisionName?: string, instanceName?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActivityLogsPageDto> {
        const response = await this.getActivityLogsRaw({ applicationName: applicationName, page: page, size: size, environmentName: environmentName, serviceName: serviceName, revisionName: revisionName, instanceName: instanceName }, initOverrides);
        return await response.value();
    }

    /**
     * Get application
     */
    async getApplicationRaw(requestParameters: GetApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationV1DtoFromJSON(jsonValue));
    }

    /**
     * Get application
     */
    async getApplication(application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationV1Dto> {
        const response = await this.getApplicationRaw({ application: application }, initOverrides);
        return await response.value();
    }

    /**
     * Get an application quickstart pipeline
     */
    async getApplicationQuickstartPipelineRaw(requestParameters: GetApplicationQuickstartPipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationQuickstartPipelineV1Dto>> {
        if (requestParameters.pipelineName === null || requestParameters.pipelineName === undefined) {
            throw new runtime.RequiredError('pipelineName','Required parameter requestParameters.pipelineName was null or undefined when calling getApplicationQuickstartPipeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationquickstartpipelines/v1/{pipelineName}`.replace(`{${"pipelineName"}}`, encodeURIComponent(String(requestParameters.pipelineName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationQuickstartPipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Get an application quickstart pipeline
     */
    async getApplicationQuickstartPipeline(pipelineName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationQuickstartPipelineV1Dto> {
        const response = await this.getApplicationQuickstartPipelineRaw({ pipelineName: pipelineName }, initOverrides);
        return await response.value();
    }

    /**
     * Get applications
     */
    async getApplicationsRaw(requestParameters: GetApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApplicationV1Dto>>> {
        const queryParameters: any = {};

        if (requestParameters.teamRefs) {
            queryParameters['teamRefs'] = requestParameters.teamRefs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApplicationV1DtoFromJSON));
    }

    /**
     * Get applications
     */
    async getApplications(teamRefs?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApplicationV1Dto>> {
        const response = await this.getApplicationsRaw({ teamRefs: teamRefs }, initOverrides);
        return await response.value();
    }

    /**
     * Get the values for compute plans
     */
    async getComputePlansV1Raw(requestParameters: GetComputePlansV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ComputePlanV1Dto>>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getComputePlansV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getComputePlansV1.');
        }

        if (requestParameters.databaseType === null || requestParameters.databaseType === undefined) {
            throw new runtime.RequiredError('databaseType','Required parameter requestParameters.databaseType was null or undefined when calling getComputePlansV1.');
        }

        const queryParameters: any = {};

        if (requestParameters.region !== undefined) {
            queryParameters['region'] = requestParameters.region;
        }

        if (requestParameters.databaseType !== undefined) {
            queryParameters['databaseType'] = requestParameters.databaseType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/databasecomputeplans/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ComputePlanV1DtoFromJSON));
    }

    /**
     * Get the values for compute plans
     */
    async getComputePlansV1(application: string, environment: string, databaseType: DatabaseTypeV1Dto, region?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ComputePlanV1Dto>> {
        const response = await this.getComputePlansV1Raw({ application: application, environment: environment, databaseType: databaseType, region: region }, initOverrides);
        return await response.value();
    }

    /**
     * Get the list of databases owned by the application
     */
    async getDatabaseBackupsV1Raw(requestParameters: GetDatabaseBackupsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatabaseBackupPageV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getDatabaseBackupsV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getDatabaseBackupsV1.');
        }

        if (requestParameters.databaseId === null || requestParameters.databaseId === undefined) {
            throw new runtime.RequiredError('databaseId','Required parameter requestParameters.databaseId was null or undefined when calling getDatabaseBackupsV1.');
        }

        if (requestParameters.databaseType === null || requestParameters.databaseType === undefined) {
            throw new runtime.RequiredError('databaseType','Required parameter requestParameters.databaseType was null or undefined when calling getDatabaseBackupsV1.');
        }

        const queryParameters: any = {};

        if (requestParameters.databaseType !== undefined) {
            queryParameters['databaseType'] = requestParameters.databaseType;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/databases/{databaseId}/backups/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"databaseId"}}`, encodeURIComponent(String(requestParameters.databaseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabaseBackupPageV1DtoFromJSON(jsonValue));
    }

    /**
     * Get the list of databases owned by the application
     */
    async getDatabaseBackupsV1(application: string, environment: string, databaseId: string, databaseType: DatabaseTypeV1Dto, status?: ResourceStateV1Dto, page?: number, size?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatabaseBackupPageV1Dto> {
        const response = await this.getDatabaseBackupsV1Raw({ application: application, environment: environment, databaseId: databaseId, databaseType: databaseType, status: status, page: page, size: size }, initOverrides);
        return await response.value();
    }

    /**
     * Get the resource owned by the application
     */
    async getDatabaseV1Raw(requestParameters: GetDatabaseV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatabaseV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getDatabaseV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getDatabaseV1.');
        }

        if (requestParameters.databaseId === null || requestParameters.databaseId === undefined) {
            throw new runtime.RequiredError('databaseId','Required parameter requestParameters.databaseId was null or undefined when calling getDatabaseV1.');
        }

        if (requestParameters.databaseType === null || requestParameters.databaseType === undefined) {
            throw new runtime.RequiredError('databaseType','Required parameter requestParameters.databaseType was null or undefined when calling getDatabaseV1.');
        }

        const queryParameters: any = {};

        if (requestParameters.databaseType !== undefined) {
            queryParameters['databaseType'] = requestParameters.databaseType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/databases/v1/{databaseId}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"databaseId"}}`, encodeURIComponent(String(requestParameters.databaseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabaseV1DtoFromJSON(jsonValue));
    }

    /**
     * Get the resource owned by the application
     */
    async getDatabaseV1(application: string, environment: string, databaseId: string, databaseType: DatabaseTypeV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatabaseV1Dto> {
        const response = await this.getDatabaseV1Raw({ application: application, environment: environment, databaseId: databaseId, databaseType: databaseType }, initOverrides);
        return await response.value();
    }

    /**
     * Get the list of databases owned by the application
     */
    async getDatabasesV1Raw(requestParameters: GetDatabasesV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatabasePageV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getDatabasesV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getDatabasesV1.');
        }

        if (requestParameters.databaseType === null || requestParameters.databaseType === undefined) {
            throw new runtime.RequiredError('databaseType','Required parameter requestParameters.databaseType was null or undefined when calling getDatabasesV1.');
        }

        const queryParameters: any = {};

        if (requestParameters.databaseType !== undefined) {
            queryParameters['databaseType'] = requestParameters.databaseType;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/databases/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabasePageV1DtoFromJSON(jsonValue));
    }

    /**
     * Get the list of databases owned by the application
     */
    async getDatabasesV1(application: string, environment: string, databaseType: DatabaseTypeV1Dto, status?: ResourceStateV1Dto, page?: number, size?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatabasePageV1Dto> {
        const response = await this.getDatabasesV1Raw({ application: application, environment: environment, databaseType: databaseType, status: status, page: page, size: size }, initOverrides);
        return await response.value();
    }

    /**
     * Get github repository templates
     */
    async getGithubRepositoryTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GithubRepositoryTemplateV1Dto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/githubrepositorytemplates/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GithubRepositoryTemplateV1DtoFromJSON));
    }

    /**
     * Get github repository templates
     */
    async getGithubRepositoryTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GithubRepositoryTemplateV1Dto>> {
        const response = await this.getGithubRepositoryTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Harbor Project for Application
     */
    async getHarborProjectForApplicationRaw(requestParameters: GetHarborProjectForApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HarborProjectV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getHarborProjectForApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/harborprojects/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HarborProjectV1DtoFromJSON(jsonValue));
    }

    /**
     * Get Harbor Project for Application
     */
    async getHarborProjectForApplication(application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HarborProjectV1Dto> {
        const response = await this.getHarborProjectForApplicationRaw({ application: application }, initOverrides);
        return await response.value();
    }

    /**
     * Get applications
     */
    async getPagedApplicationsRaw(requestParameters: GetPagedApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationsPageDto>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.teamRefs) {
            queryParameters['teamRefs'] = requestParameters.teamRefs;
        }

        if (requestParameters.applicationNameSearchKey !== undefined) {
            queryParameters['applicationNameSearchKey'] = requestParameters.applicationNameSearchKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationsPageDtoFromJSON(jsonValue));
    }

    /**
     * Get applications
     */
    async getPagedApplications(page?: number, size?: number, teamRefs?: Array<string>, applicationNameSearchKey?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationsPageDto> {
        const response = await this.getPagedApplicationsRaw({ page: page, size: size, teamRefs: teamRefs, applicationNameSearchKey: applicationNameSearchKey }, initOverrides);
        return await response.value();
    }

    /**
     * Get pipeline
     */
    async getPipelineRaw(requestParameters: GetPipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineV1Dto>> {
        if (requestParameters.pipeline === null || requestParameters.pipeline === undefined) {
            throw new runtime.RequiredError('pipeline','Required parameter requestParameters.pipeline was null or undefined when calling getPipeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pipelines/v1/{pipeline}`.replace(`{${"pipeline"}}`, encodeURIComponent(String(requestParameters.pipeline))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Get pipeline
     */
    async getPipeline(pipeline: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineV1Dto> {
        const response = await this.getPipelineRaw({ pipeline: pipeline }, initOverrides);
        return await response.value();
    }

    /**
     * Get available regions
     */
    async getRegionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/regions/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get available regions
     */
    async getRegions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getRegionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the values for supported regions
     */
    async getRegionsV1Raw(requestParameters: GetRegionsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RegionV1Dto>>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getRegionsV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getRegionsV1.');
        }

        if (requestParameters.databaseType === null || requestParameters.databaseType === undefined) {
            throw new runtime.RequiredError('databaseType','Required parameter requestParameters.databaseType was null or undefined when calling getRegionsV1.');
        }

        const queryParameters: any = {};

        if (requestParameters.databaseType !== undefined) {
            queryParameters['databaseType'] = requestParameters.databaseType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/databaseregions/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RegionV1DtoFromJSON));
    }

    /**
     * Get the values for supported regions
     */
    async getRegionsV1(application: string, environment: string, databaseType: DatabaseTypeV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RegionV1Dto>> {
        const response = await this.getRegionsV1Raw({ application: application, environment: environment, databaseType: databaseType }, initOverrides);
        return await response.value();
    }

    /**
     * Get available repositories
     */
    async getRepositoriesRaw(requestParameters: GetRepositoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepositoryV1Dto>>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling getRepositories.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repositories/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepositoryV1DtoFromJSON));
    }

    /**
     * Get available repositories
     */
    async getRepositories(query: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepositoryV1Dto>> {
        const response = await this.getRepositoriesRaw({ query: query }, initOverrides);
        return await response.value();
    }

    /**
     * Get revision
     */
    async getRevisionRaw(requestParameters: GetRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RevisionV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getRevision.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getRevision.');
        }

        if (requestParameters.revision === null || requestParameters.revision === undefined) {
            throw new runtime.RequiredError('revision','Required parameter requestParameters.revision was null or undefined when calling getRevision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/revisions/v1/{revision}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"revision"}}`, encodeURIComponent(String(requestParameters.revision))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RevisionV1DtoFromJSON(jsonValue));
    }

    /**
     * Get revision
     */
    async getRevision(application: string, environment: string, revision: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RevisionV1Dto> {
        const response = await this.getRevisionRaw({ application: application, environment: environment, revision: revision }, initOverrides);
        return await response.value();
    }

    /**
     * Get revisions
     */
    async getRevisionsRaw(requestParameters: GetRevisionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RevisionV1Dto>>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getRevisions.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getRevisions.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling getRevisions.');
        }

        const queryParameters: any = {};

        if (requestParameters.onlyActive !== undefined) {
            queryParameters['onlyActive'] = requestParameters.onlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/revisions/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RevisionV1DtoFromJSON));
    }

    /**
     * Get revisions
     */
    async getRevisions(application: string, environment: string, service: string, onlyActive?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RevisionV1Dto>> {
        const response = await this.getRevisionsRaw({ application: application, environment: environment, service: service, onlyActive: onlyActive }, initOverrides);
        return await response.value();
    }

    /**
     * Get retina schema owned by the application
     */
    async getSchemaV1Raw(requestParameters: GetSchemaV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SchemaV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getSchemaV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getSchemaV1.');
        }

        if (requestParameters.schemaId === null || requestParameters.schemaId === undefined) {
            throw new runtime.RequiredError('schemaId','Required parameter requestParameters.schemaId was null or undefined when calling getSchemaV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/schemas/v1/{schemaId}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"schemaId"}}`, encodeURIComponent(String(requestParameters.schemaId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchemaV1DtoFromJSON(jsonValue));
    }

    /**
     * Get retina schema owned by the application
     */
    async getSchemaV1(application: string, environment: string, schemaId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SchemaV1Dto> {
        const response = await this.getSchemaV1Raw({ application: application, environment: environment, schemaId: schemaId }, initOverrides);
        return await response.value();
    }

    /**
     * Get the list of schemas owned by the application
     */
    async getSchemasV1Raw(requestParameters: GetSchemasV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SchemaV1Dto>>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getSchemasV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getSchemasV1.');
        }

        const queryParameters: any = {};

        if (requestParameters.onlyReadyToUse !== undefined) {
            queryParameters['onlyReadyToUse'] = requestParameters.onlyReadyToUse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/schemas/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SchemaV1DtoFromJSON));
    }

    /**
     * Get the list of schemas owned by the application
     */
    async getSchemasV1(application: string, environment: string, onlyReadyToUse?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SchemaV1Dto>> {
        const response = await this.getSchemasV1Raw({ application: application, environment: environment, onlyReadyToUse: onlyReadyToUse }, initOverrides);
        return await response.value();
    }

    /**
     * Get secret group
     */
    async getSecretGroupRaw(requestParameters: GetSecretGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecretGroupV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getSecretGroup.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getSecretGroup.');
        }

        if (requestParameters.secretGroup === null || requestParameters.secretGroup === undefined) {
            throw new runtime.RequiredError('secretGroup','Required parameter requestParameters.secretGroup was null or undefined when calling getSecretGroup.');
        }

        const queryParameters: any = {};

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/secretgroups/v1/{secretGroup}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"secretGroup"}}`, encodeURIComponent(String(requestParameters.secretGroup))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecretGroupV1DtoFromJSON(jsonValue));
    }

    /**
     * Get secret group
     */
    async getSecretGroup(application: string, environment: string, secretGroup: string, version?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecretGroupV1Dto> {
        const response = await this.getSecretGroupRaw({ application: application, environment: environment, secretGroup: secretGroup, version: version }, initOverrides);
        return await response.value();
    }

    /**
     * Get secret groups
     */
    async getSecretGroupsRaw(requestParameters: GetSecretGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SecretGroupV1Dto>>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getSecretGroups.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getSecretGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/secretgroups/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SecretGroupV1DtoFromJSON));
    }

    /**
     * Get secret groups
     */
    async getSecretGroups(application: string, environment: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SecretGroupV1Dto>> {
        const response = await this.getSecretGroupsRaw({ application: application, environment: environment }, initOverrides);
        return await response.value();
    }

    /**
     * Get the resource owned by the tenant
     */
    async getSecretsStorageV1Raw(requestParameters: GetSecretsStorageV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecretsStorageV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getSecretsStorageV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/secretsstorage/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecretsStorageV1DtoFromJSON(jsonValue));
    }

    /**
     * Get the resource owned by the tenant
     */
    async getSecretsStorageV1(application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecretsStorageV1Dto> {
        const response = await this.getSecretsStorageV1Raw({ application: application }, initOverrides);
        return await response.value();
    }

    /**
     * Get service
     */
    async getServiceRaw(requestParameters: GetServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getService.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getService.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling getService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/v1/{service}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceV1DtoFromJSON(jsonValue));
    }

    /**
     * Get service
     */
    async getService(application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceV1Dto> {
        const response = await this.getServiceRaw({ application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

    /**
     * Get a service pipeline
     */
    async getServicePipelineRaw(requestParameters: GetServicePipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServicePipelineV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getServicePipeline.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getServicePipeline.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling getServicePipeline.');
        }

        if (requestParameters.pipelineName === null || requestParameters.pipelineName === undefined) {
            throw new runtime.RequiredError('pipelineName','Required parameter requestParameters.pipelineName was null or undefined when calling getServicePipeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/pipelines/v1/{pipelineName}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))).replace(`{${"pipelineName"}}`, encodeURIComponent(String(requestParameters.pipelineName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServicePipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Get a service pipeline
     */
    async getServicePipeline(application: string, environment: string, service: string, pipelineName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServicePipelineV1Dto> {
        const response = await this.getServicePipelineRaw({ application: application, environment: environment, service: service, pipelineName: pipelineName }, initOverrides);
        return await response.value();
    }

    /**
     * Get service pipelines
     */
    async getServicePipelinesRaw(requestParameters: GetServicePipelinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServicePipelinesPageDto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getServicePipelines.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getServicePipelines.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling getServicePipelines.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/pipelines/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServicePipelinesPageDtoFromJSON(jsonValue));
    }

    /**
     * Get service pipelines
     */
    async getServicePipelines(application: string, environment: string, service: string, page?: number, size?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServicePipelinesPageDto> {
        const response = await this.getServicePipelinesRaw({ application: application, environment: environment, service: service, page: page, size: size }, initOverrides);
        return await response.value();
    }

    /**
     * Get a service quickstart pipeline
     */
    async getServiceQuickstartPipelineRaw(requestParameters: GetServiceQuickstartPipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceQuickstartPipelineV1Dto>> {
        if (requestParameters.pipelineName === null || requestParameters.pipelineName === undefined) {
            throw new runtime.RequiredError('pipelineName','Required parameter requestParameters.pipelineName was null or undefined when calling getServiceQuickstartPipeline.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/servicequickstartpipelines/v1/{pipelineName}`.replace(`{${"pipelineName"}}`, encodeURIComponent(String(requestParameters.pipelineName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceQuickstartPipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Get a service quickstart pipeline
     */
    async getServiceQuickstartPipeline(pipelineName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceQuickstartPipelineV1Dto> {
        const response = await this.getServiceQuickstartPipelineRaw({ pipelineName: pipelineName }, initOverrides);
        return await response.value();
    }

    /**
     * Get all tags for given service. Only supported storage is Maersk Harbor (https://harbor.maersk.io/).
     */
    async getServiceTagsRaw(requestParameters: GetServiceTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceTagV1Dto>>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getServiceTags.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getServiceTags.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling getServiceTags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/{service}/tags/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceTagV1DtoFromJSON));
    }

    /**
     * Get all tags for given service. Only supported storage is Maersk Harbor (https://harbor.maersk.io/).
     */
    async getServiceTags(application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceTagV1Dto>> {
        const response = await this.getServiceTagsRaw({ application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

    /**
     * Get services
     */
    async getServicesRaw(requestParameters: GetServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceV1Dto>>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getServices.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getServices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceV1DtoFromJSON));
    }

    /**
     * Get services
     */
    async getServices(application: string, environment: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceV1Dto>> {
        const response = await this.getServicesRaw({ application: application, environment: environment }, initOverrides);
        return await response.value();
    }

    /**
     * Get the resource owned by the application
     */
    async getTopicV1Raw(requestParameters: GetTopicV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TopicV1Dto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getTopicV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getTopicV1.');
        }

        if (requestParameters.topicId === null || requestParameters.topicId === undefined) {
            throw new runtime.RequiredError('topicId','Required parameter requestParameters.topicId was null or undefined when calling getTopicV1.');
        }

        const queryParameters: any = {};

        if (requestParameters.accountsApplicationCode !== undefined) {
            queryParameters['accountsApplicationCode'] = requestParameters.accountsApplicationCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/topics/v1/{topicId}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"topicId"}}`, encodeURIComponent(String(requestParameters.topicId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopicV1DtoFromJSON(jsonValue));
    }

    /**
     * Get the resource owned by the application
     */
    async getTopicV1(application: string, environment: string, topicId: string, accountsApplicationCode?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TopicV1Dto> {
        const response = await this.getTopicV1Raw({ application: application, environment: environment, topicId: topicId, accountsApplicationCode: accountsApplicationCode }, initOverrides);
        return await response.value();
    }

    /**
     * Get the list of topics for application
     */
    async getTopicsV1Raw(requestParameters: GetTopicsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TopicV1Dto>>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling getTopicsV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling getTopicsV1.');
        }

        if (requestParameters.owned === null || requestParameters.owned === undefined) {
            throw new runtime.RequiredError('owned','Required parameter requestParameters.owned was null or undefined when calling getTopicsV1.');
        }

        const queryParameters: any = {};

        if (requestParameters.owned !== undefined) {
            queryParameters['owned'] = requestParameters.owned;
        }

        if (requestParameters.accountsApplicationCode !== undefined) {
            queryParameters['accountsApplicationCode'] = requestParameters.accountsApplicationCode;
        }

        if (requestParameters.onlyReadyToUse !== undefined) {
            queryParameters['onlyReadyToUse'] = requestParameters.onlyReadyToUse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/topics/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TopicV1DtoFromJSON));
    }

    /**
     * Get the list of topics for application
     */
    async getTopicsV1(application: string, environment: string, owned: boolean, accountsApplicationCode?: string, onlyReadyToUse?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TopicV1Dto>> {
        const response = await this.getTopicsV1Raw({ application: application, environment: environment, owned: owned, accountsApplicationCode: accountsApplicationCode, onlyReadyToUse: onlyReadyToUse }, initOverrides);
        return await response.value();
    }

    /**
     * Patch service
     */
    async patchServiceRaw(requestParameters: PatchServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceV1Dto>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling patchService.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling patchService.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling patchService.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling patchService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/v1/{service}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceV1DtoFromJSON(jsonValue));
    }

    /**
     * Patch service
     */
    async patchService(requestBody: { [key: string]: object | undefined; }, application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceV1Dto> {
        const response = await this.patchServiceRaw({ requestBody: requestBody, application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

    /**
     * Update application account.
     */
    async updateAccountV1Raw(requestParameters: UpdateAccountV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineV1Dto>> {
        if (requestParameters.accountV1Dto === null || requestParameters.accountV1Dto === undefined) {
            throw new runtime.RequiredError('accountV1Dto','Required parameter requestParameters.accountV1Dto was null or undefined when calling updateAccountV1.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateAccountV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling updateAccountV1.');
        }

        if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
            throw new runtime.RequiredError('accountId','Required parameter requestParameters.accountId was null or undefined when calling updateAccountV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/accounts/v1/{accountId}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"accountId"}}`, encodeURIComponent(String(requestParameters.accountId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccountV1DtoToJSON(requestParameters.accountV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Update application account.
     */
    async updateAccountV1(accountV1Dto: AccountV1Dto, application: string, environment: string, accountId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineV1Dto> {
        const response = await this.updateAccountV1Raw({ accountV1Dto: accountV1Dto, application: application, environment: environment, accountId: accountId }, initOverrides);
        return await response.value();
    }

    /**
     * Update application resource. This operation is asynchronous. Returned object contains the \'status\' field that can be used to track the operation progress via polling the corresponding \'GET\' endpoint. 
     */
    async updateDatabaseV1Raw(requestParameters: UpdateDatabaseV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatabaseV1Dto>> {
        if (requestParameters.databaseV1Dto === null || requestParameters.databaseV1Dto === undefined) {
            throw new runtime.RequiredError('databaseV1Dto','Required parameter requestParameters.databaseV1Dto was null or undefined when calling updateDatabaseV1.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateDatabaseV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling updateDatabaseV1.');
        }

        if (requestParameters.databaseId === null || requestParameters.databaseId === undefined) {
            throw new runtime.RequiredError('databaseId','Required parameter requestParameters.databaseId was null or undefined when calling updateDatabaseV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/databases/v1/{databaseId}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"databaseId"}}`, encodeURIComponent(String(requestParameters.databaseId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DatabaseV1DtoToJSON(requestParameters.databaseV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatabaseV1DtoFromJSON(jsonValue));
    }

    /**
     * Update application resource. This operation is asynchronous. Returned object contains the \'status\' field that can be used to track the operation progress via polling the corresponding \'GET\' endpoint. 
     */
    async updateDatabaseV1(databaseV1Dto: DatabaseV1Dto, application: string, environment: string, databaseId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatabaseV1Dto> {
        const response = await this.updateDatabaseV1Raw({ databaseV1Dto: databaseV1Dto, application: application, environment: environment, databaseId: databaseId }, initOverrides);
        return await response.value();
    }

    /**
     * Update harbor project. This operation is asynchronous. Returned object contains the \'status\' field that can be used to track the operation progress via polling the corresponding \'GET\' endpoint. 
     */
    async updateHarborProjectRaw(requestParameters: UpdateHarborProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.harborProjectV1Dto === null || requestParameters.harborProjectV1Dto === undefined) {
            throw new runtime.RequiredError('harborProjectV1Dto','Required parameter requestParameters.harborProjectV1Dto was null or undefined when calling updateHarborProject.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateHarborProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/harborprojects/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HarborProjectV1DtoToJSON(requestParameters.harborProjectV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update harbor project. This operation is asynchronous. Returned object contains the \'status\' field that can be used to track the operation progress via polling the corresponding \'GET\' endpoint. 
     */
    async updateHarborProject(harborProjectV1Dto: HarborProjectV1Dto, application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateHarborProjectRaw({ harborProjectV1Dto: harborProjectV1Dto, application: application }, initOverrides);
    }

    /**
     * Update infra broker registration
     */
    async updateInfraBrokerRaw(requestParameters: UpdateInfraBrokerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InfraBrokerV1Dto>> {
        if (requestParameters.infraBrokerV1Dto === null || requestParameters.infraBrokerV1Dto === undefined) {
            throw new runtime.RequiredError('infraBrokerV1Dto','Required parameter requestParameters.infraBrokerV1Dto was null or undefined when calling updateInfraBroker.');
        }

        if (requestParameters.infrabroker === null || requestParameters.infrabroker === undefined) {
            throw new runtime.RequiredError('infrabroker','Required parameter requestParameters.infrabroker was null or undefined when calling updateInfraBroker.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/infrabrokers/v1/{infrabroker}`.replace(`{${"infrabroker"}}`, encodeURIComponent(String(requestParameters.infrabroker))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfraBrokerV1DtoToJSON(requestParameters.infraBrokerV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfraBrokerV1DtoFromJSON(jsonValue));
    }

    /**
     * Update infra broker registration
     */
    async updateInfraBroker(infraBrokerV1Dto: InfraBrokerV1Dto, infrabroker: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InfraBrokerV1Dto> {
        const response = await this.updateInfraBrokerRaw({ infraBrokerV1Dto: infraBrokerV1Dto, infrabroker: infrabroker }, initOverrides);
        return await response.value();
    }

    /**
     * Update revision
     */
    async updateRevisionRaw(requestParameters: UpdateRevisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateRevision.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateRevision.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling updateRevision.');
        }

        if (requestParameters.revision === null || requestParameters.revision === undefined) {
            throw new runtime.RequiredError('revision','Required parameter requestParameters.revision was null or undefined when calling updateRevision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/revisions/v1/{revision}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"revision"}}`, encodeURIComponent(String(requestParameters.revision))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update revision
     */
    async updateRevision(requestBody: { [key: string]: object | undefined; }, application: string, environment: string, revision: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRevisionRaw({ requestBody: requestBody, application: application, environment: environment, revision: revision }, initOverrides);
    }

    /**
     * Set enabled flag on a server-group
     */
    async updateRevisionEnabledRaw(requestParameters: UpdateRevisionEnabledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateRevisionEnabled.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling updateRevisionEnabled.');
        }

        if (requestParameters.revision === null || requestParameters.revision === undefined) {
            throw new runtime.RequiredError('revision','Required parameter requestParameters.revision was null or undefined when calling updateRevisionEnabled.');
        }

        if (requestParameters.enabled === null || requestParameters.enabled === undefined) {
            throw new runtime.RequiredError('enabled','Required parameter requestParameters.enabled was null or undefined when calling updateRevisionEnabled.');
        }

        const queryParameters: any = {};

        if (requestParameters.enabled !== undefined) {
            queryParameters['enabled'] = requestParameters.enabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/revisions/v1/{revision}/enabled`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"revision"}}`, encodeURIComponent(String(requestParameters.revision))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set enabled flag on a server-group
     */
    async updateRevisionEnabled(application: string, environment: string, revision: string, enabled: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRevisionEnabledRaw({ application: application, environment: environment, revision: revision, enabled: enabled }, initOverrides);
    }

    /**
     * Update application schema.
     */
    async updateSchemaV1Raw(requestParameters: UpdateSchemaV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineV1Dto>> {
        if (requestParameters.schemaV1Dto === null || requestParameters.schemaV1Dto === undefined) {
            throw new runtime.RequiredError('schemaV1Dto','Required parameter requestParameters.schemaV1Dto was null or undefined when calling updateSchemaV1.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateSchemaV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling updateSchemaV1.');
        }

        if (requestParameters.schemaId === null || requestParameters.schemaId === undefined) {
            throw new runtime.RequiredError('schemaId','Required parameter requestParameters.schemaId was null or undefined when calling updateSchemaV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/schemas/v1/{schemaId}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"schemaId"}}`, encodeURIComponent(String(requestParameters.schemaId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SchemaV1DtoToJSON(requestParameters.schemaV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Update application schema.
     */
    async updateSchemaV1(schemaV1Dto: SchemaV1Dto, application: string, environment: string, schemaId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineV1Dto> {
        const response = await this.updateSchemaV1Raw({ schemaV1Dto: schemaV1Dto, application: application, environment: environment, schemaId: schemaId }, initOverrides);
        return await response.value();
    }

    /**
     * Update secret group
     */
    async updateSecretGroupRaw(requestParameters: UpdateSecretGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecretGroupV1Dto>> {
        if (requestParameters.secretGroupV1Dto === null || requestParameters.secretGroupV1Dto === undefined) {
            throw new runtime.RequiredError('secretGroupV1Dto','Required parameter requestParameters.secretGroupV1Dto was null or undefined when calling updateSecretGroup.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateSecretGroup.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling updateSecretGroup.');
        }

        if (requestParameters.secretGroup === null || requestParameters.secretGroup === undefined) {
            throw new runtime.RequiredError('secretGroup','Required parameter requestParameters.secretGroup was null or undefined when calling updateSecretGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/secretgroups/v1/{secretGroup}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"secretGroup"}}`, encodeURIComponent(String(requestParameters.secretGroup))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SecretGroupV1DtoToJSON(requestParameters.secretGroupV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecretGroupV1DtoFromJSON(jsonValue));
    }

    /**
     * Update secret group
     */
    async updateSecretGroup(secretGroupV1Dto: SecretGroupV1Dto, application: string, environment: string, secretGroup: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecretGroupV1Dto> {
        const response = await this.updateSecretGroupRaw({ secretGroupV1Dto: secretGroupV1Dto, application: application, environment: environment, secretGroup: secretGroup }, initOverrides);
        return await response.value();
    }

    /**
     * Update service
     */
    async updateServiceRaw(requestParameters: UpdateServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineV1Dto>> {
        if (requestParameters.serviceV1Dto === null || requestParameters.serviceV1Dto === undefined) {
            throw new runtime.RequiredError('serviceV1Dto','Required parameter requestParameters.serviceV1Dto was null or undefined when calling updateService.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateService.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling updateService.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling updateService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/{application}/environments/{environment}/services/v1/{service}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceV1DtoToJSON(requestParameters.serviceV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Update service
     */
    async updateService(serviceV1Dto: ServiceV1Dto, application: string, environment: string, service: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineV1Dto> {
        const response = await this.updateServiceRaw({ serviceV1Dto: serviceV1Dto, application: application, environment: environment, service: service }, initOverrides);
        return await response.value();
    }

    /**
     * Update application topic.
     */
    async updateTopicV1Raw(requestParameters: UpdateTopicV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineV1Dto>> {
        if (requestParameters.topicV1Dto === null || requestParameters.topicV1Dto === undefined) {
            throw new runtime.RequiredError('topicV1Dto','Required parameter requestParameters.topicV1Dto was null or undefined when calling updateTopicV1.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling updateTopicV1.');
        }

        if (requestParameters.environment === null || requestParameters.environment === undefined) {
            throw new runtime.RequiredError('environment','Required parameter requestParameters.environment was null or undefined when calling updateTopicV1.');
        }

        if (requestParameters.topicId === null || requestParameters.topicId === undefined) {
            throw new runtime.RequiredError('topicId','Required parameter requestParameters.topicId was null or undefined when calling updateTopicV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/environments/{environment}/retina/topics/v1/{topicId}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))).replace(`{${"environment"}}`, encodeURIComponent(String(requestParameters.environment))).replace(`{${"topicId"}}`, encodeURIComponent(String(requestParameters.topicId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TopicV1DtoToJSON(requestParameters.topicV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineV1DtoFromJSON(jsonValue));
    }

    /**
     * Update application topic.
     */
    async updateTopicV1(topicV1Dto: TopicV1Dto, application: string, environment: string, topicId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineV1Dto> {
        const response = await this.updateTopicV1Raw({ topicV1Dto: topicV1Dto, application: application, environment: environment, topicId: topicId }, initOverrides);
        return await response.value();
    }

    /**
     * Upsert tenant resource. This operation is asynchronous. Returned object contains the \'status\' field that can be used to track the operation progress via polling the corresponding \'GET\' endpoint. 
     */
    async upsertSecretsStorageV1Raw(requestParameters: UpsertSecretsStorageV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.secretsStorageV1Dto === null || requestParameters.secretsStorageV1Dto === undefined) {
            throw new runtime.RequiredError('secretsStorageV1Dto','Required parameter requestParameters.secretsStorageV1Dto was null or undefined when calling upsertSecretsStorageV1.');
        }

        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling upsertSecretsStorageV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/applications/{application}/secretsstorage/v1`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SecretsStorageV1DtoToJSON(requestParameters.secretsStorageV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upsert tenant resource. This operation is asynchronous. Returned object contains the \'status\' field that can be used to track the operation progress via polling the corresponding \'GET\' endpoint. 
     */
    async upsertSecretsStorageV1(secretsStorageV1Dto: SecretsStorageV1Dto, application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.upsertSecretsStorageV1Raw({ secretsStorageV1Dto: secretsStorageV1Dto, application: application }, initOverrides);
    }

}
