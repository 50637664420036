/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDto';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateResourcesDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateResourcesDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateResourcesDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateResourcesDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateResourcesDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateResourcesDto';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDto';

/**
 * 
 * @export
 * @interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
 */
export interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    region: string;
    /**
     * 
     * @type {number}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    replicas: number;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateResourcesDto}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    resources: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateResourcesDto;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDto}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    rollout: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDto;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    imageUpdatePolicy: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDto;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    healthCheck: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDto}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    observability: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDto;
    /**
     * 
     * @type {number}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    exposedPort: number;
    /**
     * 
     * @type {boolean}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    incomingTrafficEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    acceptExternalTraffic: boolean;
    /**
     * 
     * @type {string}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    externalTrafficAuthentication: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoExternalTrafficAuthenticationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    spotComputeEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    scaleDownAfterDays?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto
     */
    active: boolean;
}


/**
 * @export
 */
export const GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoExternalTrafficAuthenticationEnum = {
    AzureAuth: 'AZURE_AUTH',
    BasicAuth: 'BASIC_AUTH',
    NoAuth: 'NO_AUTH'
} as const;
export type GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoExternalTrafficAuthenticationEnum = typeof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoExternalTrafficAuthenticationEnum[keyof typeof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoExternalTrafficAuthenticationEnum];


/**
 * Check if a given object implements the GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto interface.
 */
export function instanceOfGithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "replicas" in value;
    isInstance = isInstance && "resources" in value;
    isInstance = isInstance && "rollout" in value;
    isInstance = isInstance && "imageUpdatePolicy" in value;
    isInstance = isInstance && "healthCheck" in value;
    isInstance = isInstance && "observability" in value;
    isInstance = isInstance && "exposedPort" in value;
    isInstance = isInstance && "incomingTrafficEnabled" in value;
    isInstance = isInstance && "acceptExternalTraffic" in value;
    isInstance = isInstance && "externalTrafficAuthentication" in value;
    isInstance = isInstance && "spotComputeEnabled" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoFromJSON(json: any): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto {
    return GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoFromJSONTyped(json, false);
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'region': json['region'],
        'replicas': json['replicas'],
        'resources': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateResourcesDtoFromJSON(json['resources']),
        'rollout': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoFromJSON(json['rollout']),
        'imageUpdatePolicy': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoFromJSON(json['imageUpdatePolicy']),
        'healthCheck': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoFromJSON(json['healthCheck']),
        'observability': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDtoFromJSON(json['observability']),
        'exposedPort': json['exposedPort'],
        'incomingTrafficEnabled': json['incomingTrafficEnabled'],
        'acceptExternalTraffic': json['acceptExternalTraffic'],
        'externalTrafficAuthentication': json['externalTrafficAuthentication'],
        'spotComputeEnabled': json['spotComputeEnabled'],
        'scaleDownAfterDays': !exists(json, 'scaleDownAfterDays') ? undefined : json['scaleDownAfterDays'],
        'active': json['active'],
    };
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoToJSON(value?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'region': value.region,
        'replicas': value.replicas,
        'resources': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateResourcesDtoToJSON(value.resources),
        'rollout': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoToJSON(value.rollout),
        'imageUpdatePolicy': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateImageUpdatePolicyDtoToJSON(value.imageUpdatePolicy),
        'healthCheck': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoToJSON(value.healthCheck),
        'observability': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDtoToJSON(value.observability),
        'exposedPort': value.exposedPort,
        'incomingTrafficEnabled': value.incomingTrafficEnabled,
        'acceptExternalTraffic': value.acceptExternalTraffic,
        'externalTrafficAuthentication': value.externalTrafficAuthentication,
        'spotComputeEnabled': value.spotComputeEnabled,
        'scaleDownAfterDays': value.scaleDownAfterDays,
        'active': value.active,
    };
}

